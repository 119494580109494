<template>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group mb-3">
        <label>Date Range</label>
        <date-picker
            v-model="filters.daterange"
            range
            append-to-body
            lang="en"
            confirm
        ></date-picker>
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex">
        <div class="form-check mt-4 mr-3">
          <input class="form-check-input" type="radio" v-model="filters.type" value="overall" name="formRadios" checked="">
          <label class="form-check-label">
            OverAll
          </label>
        </div>
        <div class="form-check mt-4 mr-3">
          <input class="form-check-input" type="radio" v-model="filters.type" value="articles" name="formRadios" checked="">
          <label class="form-check-label">
            Articles
          </label>
        </div>
        <div class="form-check mt-4 mr-3">
          <input class="form-check-input" type="radio" v-model="filters.type" value="newsletters" name="formRadios" checked="">
          <label class="form-check-label">
            NewsLetters
          </label>
        </div>
        <div class="form-check mt-4 mr-3">
          <input class="form-check-input" type="radio" v-model="filters.type" value="videos" name="formRadios" checked="">
          <label class="form-check-label">
            Videos
          </label>
        </div>
        <div class="form-check mt-4 mr-3">
          <input class="form-check-input" type="radio" v-model="filters.type" value="cases" name="formRadios" checked="">
          <label class="form-check-label">
            Cases
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="">
        <div class="d-flex overall-count">
          <label class="mr-2 pb-2 mt-2">Show By Percentage</label>
          <div class="mb-3 pt-2">
            <switches
                v-model="filters.show_by_percentage"
                type-bold="true"
                color="success"
                class="mb-0"
            ></switches>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Switches from "vue-switches";


export default {
  //   props : ['projects'],
  components: {
    DatePicker,
    Switches
  },
  data() {
    return {
      filters: {
        startDate: null,
        endDate: null,
        type : 'overall',
        show_by_percentage : false
      }
    };
  },
  methods: {
    getFormatedDate() {
      try {
        this.filters.daterange = moment(this.filters.daterange).format(
            "yyyy-mm-dd"
        );
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async loadFilters() {
    },
  },
  mounted() {
    this.loadFilters();
  },
  watch: {
    filters: {
      deep: true,
      handler(v) {
        this.$emit("filters", v);
      },
    }
  },
};
</script>
