<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0 table-sm table-bordered table-hover border">
              <thead>
              <tr>
                <th v-for="header in headers" v-text="header.display" :key="header.key"></th>
              </tr>
              </thead>
              <tbody :key="key">
                <tr v-for="(d,di) in data" :key="di">
                  <td v-text="d.date"></td>
                  <td v-text="d.registration_count === undefined ? 0 : d.registration_count"></td>
                  <td v-for="i in 31" :key="i" :style="{'background-color': d['day'+(i-1)] !== undefined ? d['day'+(i-1)][filters.type + '_color'] : '#fff'}">
                    <template v-if="d['day'+(i-1)] !== undefined">
                      <template v-if="filters.type === 'articles'">
                        <template v-if="filters.show_by_percentage">
                          <span>{{d['day'+(i-1)].article_percentage}} %</span>
                        </template>
                        <template v-else>
                          {{d['day'+(i-1)].article_count}}
                        </template>
                      </template>
                      <template v-else-if="filters.type === 'newsletters'">
                        <template v-if="filters.show_by_percentage">
                          {{d['day'+(i-1)].newsletter_percentage}}%
                        </template>
                        <template v-else>
                          {{d['day'+(i-1)].newsletter_count}}
                        </template>
                      </template>
                      <template v-else-if="filters.type === 'videos'">
                        <template v-if="filters.show_by_percentage">
                          {{d['day'+(i-1)].video_percentage}}%
                        </template>
                        <template v-else>
                          {{d['day'+(i-1)].video_count}}
                        </template>
                      </template>
                      <template v-else-if="filters.type === 'cases'">
                        <template v-if="filters.show_by_percentage">
                          {{d['day'+(i-1)].case_percentage}}%
                        </template>
                        <template v-else>
                          {{d['day'+(i-1)].case_count}}
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="filters.show_by_percentage">
                          {{d['day'+(i-1)].count_percentage}}%
                        </template>
                        <template v-else>
                          {{d['day'+(i-1)].count}}
                        </template>
                      </template>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "./filters.vue";
import moment from "moment";
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";

export default {
  components: {Layout,PageHeader,Filters},
  name: "retention",
  mixins : [filterMixins],
  data(){
    return{
      title: "Retention Analysis",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Retention",
          active: true,
        },
      ],
      filters : {
        type : 'overall'
      },
      headers : [
        {"key" : "date", "display" : "Date"},
        {"key" : "registration_count", "display" : "Registration Count"}
      ],
      data : [],
      from_date : moment().subtract(30,'days').format(),
      to_date : moment().format(),
      key : 0,
      palette : [
        {
          "key" : 0,
          "percentage_range" : '0-12.5',
          "color" : '#EBF5FB'
        },
        {
          "key" : 1,
          "percentage_range" : '12.5-25',
          "color" : '#D6EAF8'
        },
        {
          "key" : 2,
          "percentage_range" : '25-37.5',
          "color" : '#AED6F1'
        },
        {
          "key" : 3,
          "percentage_range" : '37.5-50',
          "color" : '#85C1E9'
        },
        {
          "key" : 4,
          "percentage_range" : '50-62.5',
          "color" : '#5DADE2'
        },
        {
          "key" : 5,
          "percentage_range" : '62.5-75',
          "color" : '#3498DB'
        },
        {
          "key" : 6,
          "percentage_range" : '75-87.5',
          "color" : '#2E86C1'
        },
        {
          "key" : 7,
          "percentage_range" : '87.5-100',
          "color" : '#2874A6'
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  watch : {
    filters : {
      deep : true,
      handler(v){
        if(v.daterange){
          this.from_date = moment(v.daterange[0]).format();
          this.to_date = moment(v.daterange[1]).format();
        }
        this.data = [];
        let from_date = moment(this.from_date).clone();
        while(from_date.diff(this.to_date) < 0) {
          this.data.push({
            "date" : from_date.format('MMM Do'),
            "date_original" : from_date.format('YYYY-MM-DD')
          });
          from_date.add(1, 'days');
        }
        this.fetchRetentionData();
      }
    }
  },
  methods : {
    setFilters(v) {
      this.filters = v;
    },
    init(){
      let from_date = moment(this.from_date).clone();
      this.data = [];
      for (let i = 0; i< 31; i++){
        this.headers.push({
          "key" : i,
          "display" : "Day #"+i
        });
        this.data.push({
          "date" : from_date.format('MMM Do'),
          "date_original" : from_date.format('YYYY-MM-DD')
        });
        from_date.add(1,'day');
      }
      this.$nextTick(async() => {
        await this.fetchRetentionData();
      });
    },
    async fetchRetentionData(){
      try {
        let res = await this.$http.get(appConfig.api_base_url + '/forum/retention-logs',{
          params : {
            from_date : moment(this.from_date).format('YYYY-MM-DD'),
            to_date : moment(this.to_date).format('YYYY-MM-DD'),
          }
        });
        if((res.data.cohort_logs).length > 0){
          await this.loadDataToDataTable(res.data.cohort_logs);
        }
      }catch (e){
        console.log(e);
      }
    },
    async loadDataToDataTable(list){
      try {
        for(let i=0; i < list.length; i++){
          let index = this.data.findIndex(d => d.date_original === list[i].master_date);
          if(index !== -1){
            this.data[index].registration_count = list[i].registration_count;
            let list_data = list.filter(l => l.master_date === list[i].master_date);
            let registration_count = parseInt(this.data[index].registration_count);
            list_data.forEach(l => {
              let color_range_percentage = {
                "total_percentage" : (parseInt(l.count) / Math.max(...list_data.map(l => l.count))) * 100,
                "article_percentage" : (parseInt(l.article_count) / Math.max(...list_data.map(l => l.article_count))) * 100,
                "video_percentage" : (parseInt(l.video_count) / Math.max(...list_data.map(l => l.video_count))) * 100,
                "case_percentage" : (parseInt(l.case_count) / Math.max(...list_data.map(l => l.case_count))) * 100,
                "newsletter_percentage" : (parseInt(l.newsletter_count) / Math.max(...list_data.map(l => l.newsletter_count))) * 100,
              }
              let total_percentage = Math.round((parseInt(l.count) / registration_count) * 100);
              let article_percentage = Math.round((parseInt(l.article_count) / registration_count) * 100);
              let video_percentage = Math.round((parseInt(l.video_count) / registration_count) * 100);
              let case_percentage = Math.round((parseInt(l.case_count) / registration_count) * 100);
              let newsletter_percentage = Math.round((parseInt(l.newsletter_count) / registration_count) * 100);
              this.data[index]['day'+l.day] = {
                "count" : l.count,
                "article_count" : l.article_count,
                "video_count" : l.video_count,
                "case_count" : l.case_count,
                "newsletter_count" : l.newsletter_count,
                "count_percentage" : total_percentage,
                "article_percentage" : article_percentage,
                "video_percentage" : video_percentage,
                "case_percentage" : case_percentage,
                "newsletter_percentage" : newsletter_percentage,
                "overall_color" : this.getValue(color_range_percentage.total_percentage),
                "articles_color" : this.getValue(color_range_percentage.article_percentage),
                "videos_color" : this.getValue(color_range_percentage.video_percentage),
                "cases_color" : this.getValue(color_range_percentage.case_percentage),
                "newsletters_color" : this.getValue(color_range_percentage.newsletter_percentage)
              }
            });
          }
        }
        this.key +=1;
      }catch (e){
        console.log(e);
      }
    },
    getValue(search) {
      for(const range of this.palette.map(p => p.percentage_range)) {
        var split = range.split("-");
        if( search >= parseInt(split[0]) && search <= parseInt(split[1] || split[0]) )
          return this.palette.filter(p => p.percentage_range === range)[0].color;
      }
    }
  }
}
</script>

<style scoped>

</style>